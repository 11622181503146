import { FC } from 'react';
import { ImageObject } from '@/types';

const ColorOption: FC<
  Omit<ImageObject, 'images' | 'msrp' | 'rgb'> & {
    handleOnClick?: () => void;
    selected?: boolean;
    size?: string;
    rgb?: string;
  }
> = ({ rgb, selected = false, handleOnClick, colorName, size = '16' }) => {
  const formattedRgb = rgb?.replace('#', '');
  return (
    <div
      className={`${
        handleOnClick ? 'cursor-pointer' : ''
      } mr-2 flex items-center rounded-full ${
        selected && 'ring ring-black ring-offset-2'
      } ${colorName === 'No Preference' && 'mt-2 border-2 border-gray-400'}`}
      onClick={handleOnClick}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor:
          colorName === 'No Preference' ? 'white' : `#${formattedRgb}`,
      }}
    >
      {colorName === 'No Preference' && (
        <div className="h-0 w-full -rotate-45 border-t-2 border-gray-400" />
      )}
    </div>
  );
};

export default ColorOption;
