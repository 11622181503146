import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import { CollapsibleSection } from '@/components/atoms/legacy/CollapsibleSection/CollapsibleSection';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon/Icon';
import Image from '@/components/atoms/Image';
import ColorCard from '@/components/molecules/ColorCard/ColorCard';
import ColorOption from '@/components/molecules/ColorOption/ColorOption';
import ExtendedOptionCard from '@/components/molecules/ExtendedOptionsCard/ExtendedOptionsCard';
import IncentivesOverlay from '@/components/molecules/IncentivesOverlay/IncentivesOverlay';
import TrimCard from '@/components/molecules/TrimCard/TrimCard';
import {
  FallBackColor,
  getDefaultImage,
  getRange,
  recoverySelections,
} from '@/components/organisms/ChooseTrimForm/helpers';
import { priceFormatter } from '@/lib/priceFormatter';
import {
  GOOGLE_TRACK_INFO,
  POPULAR_OPTIONS,
  PRICE_COMING_SOON,
} from '@/lib/constants';
import { useSession } from '@/context/SavedVehicles';
import { useSearchQuery } from '@/hooks/useQuery';
import {
  ColorObject,
  ExtendedOptions,
  ExtendedOptionsFields,
  ImageObject,
  MakePageState,
  ModelPageState,
  TrimPageState,
  TrimStyle,
} from '@/types';
import { useRouter } from 'next/router';
import {
  FC,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { NoImageWrapper } from '@/components/molecules/NoImageWrapper/NoImageWrapper';
import { CustomOrderCardProps } from '../CustomOrderListingCard/CustomOrderListingCard';

export type ChooseTrimFormProps = {
  trimStyles: TrimStyle[];
  build: CustomOrderCardProps | null;
};

const SELECT_EXTERIOR = 'SELECT_EXTERIOR';
const SELECT_INTERIOR = 'SELECT_INTERIOR';
const SELECT_IMAGE = 'SELECT_IMAGE';
const ADD_EXTENDED_OPTION = 'ADD_EXTENDED_OPTION';
const REMOVE_EXTENDED_OPTION = 'REMOVE_EXTENDED_OPTION';
const CLEAR_EXTENDED_OPTION = 'CLEAR_EXTENDED_OPTION';
const SET_LOADING_IMAGE = 'SET_LOADING_IMAGE';
const SET_OPEN_INCENTIVES_OVERLAY = 'SET_OPEN_INCENTIVES_OVERLAY';
const SET_SELECTED_TRIM = 'SET_SELECTED_TRIM';

type ComponentState = {
  selectedTrim?: TrimStyle;
  selectedExteriorColor: ImageObject;
  selectedInteriorColor: ColorObject;
  selectedImage?: ImageObject['images'][0];
  selectedExtendedOptions: ExtendedOptionsFields[];
  openIncentivesOverlay: boolean;
  loadingImage: boolean;
};
type UpdateSelectedExteriorAction = {
  type: typeof SELECT_EXTERIOR;
  val: ImageObject;
};

type UpdateSelectedInteriorAction = {
  type: typeof SELECT_INTERIOR;
  val: ColorObject;
};

type UpdateSelectedImageAction = {
  type: typeof SELECT_IMAGE;
  val: ImageObject['images'][0];
};

type UpdateSelectedExtendedOptionsAction = {
  type: typeof ADD_EXTENDED_OPTION;
  val: ExtendedOptionsFields;
};

type RemoveExtendedOptionAction = {
  type: typeof REMOVE_EXTENDED_OPTION;
  val: ExtendedOptionsFields;
};

type ClearExtendedOptionAction = {
  type: typeof CLEAR_EXTENDED_OPTION;
};

type SetLoadingImageAction = {
  type: typeof SET_LOADING_IMAGE;
  val: boolean;
};

type SetOpenIncentivesOverlayAction = {
  type: typeof SET_OPEN_INCENTIVES_OVERLAY;
  val: boolean;
};

type SetSelectedTrimAction = {
  type: typeof SET_SELECTED_TRIM;
  val: TrimStyle;
};

type ComponentAction =
  | UpdateSelectedExteriorAction
  | UpdateSelectedInteriorAction
  | UpdateSelectedImageAction
  | UpdateSelectedExtendedOptionsAction
  | RemoveExtendedOptionAction
  | ClearExtendedOptionAction
  | SetLoadingImageAction
  | SetOpenIncentivesOverlayAction
  | SetSelectedTrimAction;

type ComponentReducer = Reducer<ComponentState, ComponentAction>;

export const ChooseTrimForm: FC<ChooseTrimFormProps> = ({
  trimStyles,
  build,
}) => {
  const router = useRouter();
  const { saveSession } = useSession();
  const [sessionId] = useState((router.query.sessionId as string) || v4());
  const shouldSaveSession = !router.query.sessionId;

  const defaultTrim = trimStyles.filter((t) => t.msrp > 0)[0] || trimStyles[0];
  const [currentExtendedOptions, setCurrentExtendedOptions] =
    useState<ExtendedOptions>({});

  const initialState: Partial<ComponentState> = {
    selectedImage: build?.image
      ? {
          href: build.image,
          shotCode: '03',
          shotDest: '',
          background: 'Transparent',
          width: 0,
          height: 0,
        }
      : undefined,
    selectedExtendedOptions: [],
    openIncentivesOverlay: false,
    loadingImage: false,
    selectedTrim: undefined,
  };

  const reducer: ComponentReducer = (state, action) => {
    switch (action.type) {
      case SET_SELECTED_TRIM: {
        if (action.val.trimId === state.selectedTrim?.trimId) {
          return state;
        }

        return {
          ...state,
          selectedTrim: action.val,
          // clean up other selection on trim change
          selectedExtendedOptions: [],
          selectedExteriorColor: getDefaultImage(action.val.exteriorImages),
          selectedInteriorColor: FallBackColor,
        };
      }

      case SELECT_EXTERIOR: {
        return {
          ...state,
          selectedExteriorColor: action.val,
        };
      }
      case SELECT_INTERIOR: {
        return {
          ...state,
          selectedInteriorColor: action.val,
        };
      }
      case SELECT_IMAGE: {
        return {
          ...state,
          selectedImage: action.val,
        };
      }
      case ADD_EXTENDED_OPTION: {
        const { description } = action.val;
        if (
          state.selectedExtendedOptions.find(
            (option) => option.description === description
          )
        ) {
          return state;
        }
        return {
          ...state,
          selectedExtendedOptions: [
            ...state.selectedExtendedOptions,
            action.val,
          ],
        };
      }
      case REMOVE_EXTENDED_OPTION: {
        return {
          ...state,
          selectedExtendedOptions: state.selectedExtendedOptions.filter(
            (option: ExtendedOptionsFields) =>
              option.description !== action.val.description
          ),
        };
      }
      case CLEAR_EXTENDED_OPTION: {
        return {
          ...state,
          selectedExtendedOptions: [],
        };
      }
      case SET_LOADING_IMAGE: {
        return {
          ...state,
          loadingImage: action.val,
        };
      }
      case SET_OPEN_INCENTIVES_OVERLAY: {
        return {
          ...state,
          openIncentivesOverlay: action.val,
        };
      }
      default:
        return state;
    }
  };

  const { data, next } = useSearchQuery<
    TrimPageState,
    MakePageState & ModelPageState
  >('/build/[location]/[make]/[year]/[model]/review');

  const [state, dispatch] = useReducer<
    ComponentReducer,
    Partial<ComponentState>
  >(reducer, initialState, (x) => {
    const defaultExterior = x.selectedImage
      ? {
          code: '',
          rgb: '',
          colorName: 'Build',
          msrp: 0,
          images: [x.selectedImage],
        }
      : getDefaultImage(defaultTrim.exteriorImages);

    return {
      ...x,
      selectedExteriorColor: defaultExterior,
      selectedInteriorColor: FallBackColor,
      selectedImage: x.selectedImage || undefined,
      selectedExtendedOptions: [],
      openIncentivesOverlay: false,
      loadingImage: false,
    };
  });

  const {
    selectedTrim,
    selectedExteriorColor,
    selectedInteriorColor,
    selectedImage,
    openIncentivesOverlay,
    loadingImage,
    selectedExtendedOptions,
  } = state;

  const selectedExtendedOptionsCache = new Set(
    selectedExtendedOptions.map(
      (option: ExtendedOptionsFields) => option.description
    )
  );

  const setOpenIncentivesOverlay = (val: boolean) => {
    dispatch({
      type: SET_OPEN_INCENTIVES_OVERLAY,
      val,
    });
  };

  const setSelectedTrim = (val: TrimStyle) => {
    dispatch({
      type: SET_SELECTED_TRIM,
      val,
    });
  };

  const setLoadingImage = (val: boolean) => {
    dispatch({
      type: SET_LOADING_IMAGE,
      val,
    });
  };

  const setSelectedImage = (val: ImageObject['images'][0]) => {
    dispatch({
      type: SELECT_IMAGE,
      val,
    });
  };

  const setSelectedExteriorColor = (val: ImageObject) => {
    dispatch({
      type: SELECT_EXTERIOR,
      val,
    });
  };

  const setSelectedInteriorColor = (val: ColorObject) => {
    dispatch({
      type: SELECT_INTERIOR,
      val,
    });
  };

  const setExtendedOption = (val: ExtendedOptionsFields) => {
    dispatch({
      type: ADD_EXTENDED_OPTION,
      val,
    });
  };

  const removeExtendedOption = (val: ExtendedOptionsFields) => {
    const { description } = val;
    selectedExtendedOptionsCache.delete(description);
    dispatch({
      type: REMOVE_EXTENDED_OPTION,
      val,
    });
  };

  const clearSelectedExtendedOptionsList = () => {
    dispatch({
      type: CLEAR_EXTENDED_OPTION,
    });
  };

  const handleCloseIncentivesOverlay = () => {
    setOpenIncentivesOverlay(false);
  };

  const handleOpenIncentivesOverlay = () => {
    setOpenIncentivesOverlay(true);
  };

  useEffect(() => {
    const {
      trim: trimName,
      exteriorName,
      interiorColorName,
      extendOptionCodes = [],
    } = data;
    if (trimName && exteriorName && interiorColorName) {
      const { trim, exteriorColor, interiorColor, extendedOptions } =
        recoverySelections({
          extendOptionCodes,
          trimName,
          exteriorName,
          interiorColorName,
          trims: trimStyles,
        });

      setSelectedTrim(trim);
      setSelectedExteriorColor(exteriorColor);
      setSelectedInteriorColor(interiorColor);
      setSelectedImage(exteriorColor.images[0]);
      extendedOptions.forEach((option) => {
        setExtendedOption(option);
      });
    }
  }, [trimStyles, data]);

  useEffect(() => {
    const newImage =
      selectedExteriorColor?.images?.[0] || initialState.selectedImage;

    if (selectedImage?.href && newImage.href !== selectedImage.href) {
      setSelectedImage(newImage);
    }
  }, [selectedExteriorColor, initialState.selectedImage, selectedImage]);

  const trim = selectedTrim || defaultTrim;

  const selectedTrimId = selectedTrim?.trimId || defaultTrim.trimId;
  const displayImage = selectedImage ||
    selectedExteriorColor?.images?.[0] || {
      href: '',
      shotDest: 'main image',
    };

  const allExteriors: ImageObject[] = [
    getDefaultImage(selectedTrim?.exteriorImages || defaultTrim.exteriorImages),
    ...(selectedTrim?.exteriorImages || []),
  ].filter((image) => image.colorName !== 'Unknown');

  const allInteriors: ColorObject[] = [
    FallBackColor,
    ...(selectedTrim?.interiorColors || []),
  ];

  useEffect(() => {
    const getExtendedOptions = () => {
      const trimStyle = trimStyles.find(
        (option) => option.trimId === selectedTrimId
      );
      const { extendedOptions } = trimStyle || {};
      if (extendedOptions) {
        setCurrentExtendedOptions(extendedOptions);
      }
    };

    getExtendedOptions();
  }, [selectedTrimId, trimStyles]);

  Object.keys(currentExtendedOptions).forEach((category) => {
    currentExtendedOptions[category].forEach((option) => {
      if (option.mandatory) {
        setExtendedOption(option);
      }
    });
  });

  const extendedOptionsMSRP = selectedExtendedOptions.reduce(
    (accumulator: number, option: ExtendedOptionsFields) => {
      return accumulator + option.msrp;
    },
    0
  );

  const totalMSRP =
    trim.msrp +
    (selectedInteriorColor?.msrp || 0) +
    (selectedExteriorColor?.msrp || 0) +
    (extendedOptionsMSRP || 0);

  const incentiveAmount =
    typeof selectedTrim?.incentivesTotal === 'number'
      ? selectedTrim?.incentivesTotal
      : null;
  const incentivesList = selectedTrim?.incentives;

  const range = getRange(trim);
  const header = (
    <div className="z-100 flex max-w-full flex-wrap items-start justify-between gap-2.5 border-b border-gray-300 bg-white py-5">
      <div className="inline-flex w-full flex-col items-start justify-center gap-0.5 md:w-auto">
        <div className="text-subtitle14px font-light md:text-subtitle16px">
          {selectedTrim?.year || defaultTrim.year} ·{' '}
          {selectedTrim?.make || defaultTrim.make}
        </div>
        <div className="text-subtitle14px !font-medium md:text-title20px">
          {trim.model}
        </div>
      </div>

      <div className="flex h-[50px] w-full items-start justify-between gap-[20px] md:w-auto md:justify-start md:gap-[30px]">
        <div className="inline-flex flex-col items-start justify-start gap-0.5">
          <div className="text-subtitle14px font-light md:text-subtitle16px">
            Est. Range
          </div>
          <div>
            <span className="pr-[0.25rem] text-subtitle14px !font-medium md:text-subtitle16px">
              {range?.value}
            </span>
            <span className="text-subtitle14px font-light md:text-subtitle16px">
              {range?.unit}
            </span>
          </div>
        </div>

        <div className="h-full w-0 border-r border-gray-300"></div>

        <div className="inline-flex flex-col items-start justify-start gap-0.5">
          <div className="flex h-full flex-col items-start justify-start gap-0.5">
            <div className="text-subtitle14px font-light md:text-subtitle16px">
              Est. MSRP
            </div>
            <div className="text-subtitle14px !font-medium md:text-subtitle16px">
              {priceFormatter(totalMSRP, PRICE_COMING_SOON)}
            </div>
          </div>
        </div>

        <div className="inline-flex flex-col items-start justify-start gap-0.5">
          <div className="flex h-full flex-col items-start justify-start gap-0.5">
            <div className="text-subtitle14px font-light md:text-subtitle16px">
              Est. Incentives
            </div>
            <div className="flex flex-row gap-2 text-subtitle14px !font-medium md:text-subtitle16px">
              {incentiveAmount !== null
                ? `$${incentiveAmount?.toLocaleString()}`
                : 'To Be Determined'}
              {incentiveAmount !== null && (
                <div className="m-auto" onClick={handleOpenIncentivesOverlay}>
                  <Icon iconId={IconIds.Information2}></Icon>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const saveUserSession = useCallback(async () => {
    if (shouldSaveSession) {
      await saveSession({
        id: sessionId,
        year: parseInt(defaultTrim.year, 10),
        make: defaultTrim.make,
        model: defaultTrim.model,
        image: displayImage.href,
        trim: defaultTrim.trim,
        price: `$${totalMSRP.toLocaleString()}`,
      });
    }
  }, [
    sessionId,
    totalMSRP,
    defaultTrim,
    saveSession,
    displayImage.href,
    shouldSaveSession,
  ]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        await saveUserSession();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('pagehide', saveUserSession);
    router.events.on('routeChangeStart', saveUserSession);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('pagehide', saveUserSession);
      router.events.off('routeChangeStart', saveUserSession);
    };
  }, [saveUserSession, router.events]);

  return (
    <div className={'relative min-h-[80vh]'}>
      {/* sticky */}

      {openIncentivesOverlay && (
        <IncentivesOverlay
          incentives={incentivesList || []}
          incentiveAmount={incentiveAmount || 0}
          open={openIncentivesOverlay}
          close={handleCloseIncentivesOverlay}
        />
      )}

      <div className={'sticky top-0 z-10 flex w-full flex-col pb-[40px]'}>
        {header}
      </div>

      <div className="flex w-full flex-col gap-[40px] md:flex-row">
        <div className="h-auto md:min-h-[400px] md:w-6/12">
          <div className="top-[120px] flex flex-col md:sticky">
            <div
              className={`h-content relative flex w-full items-center justify-center p-[10px] ${
                loadingImage ? 'animate-pulse' : ''
              } `}
            >
              {displayImage?.href ? (
                <Image
                  priority
                  width={400}
                  height={400}
                  quality={100}
                  className={'!h-auto w-full'}
                  key={displayImage?.href}
                  src={displayImage?.href}
                  alt={displayImage?.shotDest || 'main image'}
                  loading={'eager'}
                  onLoad={() => {
                    setLoadingImage(false);
                  }}
                />
              ) : (
                <div className="flex h-[300px] w-full bg-gradient-to-r from-lightTheme-start to-lightTheme-end">
                  <NoImageWrapper
                    className="m-auto mb-s flex-col items-center justify-center text-blue-dark opacity-40"
                    fillColor="#0E326A"
                    height="65px"
                    width="64px"
                    make={defaultTrim.make}
                  />
                </div>
              )}
            </div>
            <div className="relative flex w-full flex-wrap">
              {[
                ...(selectedExteriorColor?.images || []),
                ...trim.interiorImages,
              ]
                .slice(0, 3)
                .map((image, index) => (
                  <div
                    key={index}
                    className={`w-4/12 cursor-pointer rounded border-2 p-1 px-1 ${
                      image.shotCode === displayImage?.shotCode
                        ? 'border-gray'
                        : 'border-white'
                    }`}
                    onClick={() => {
                      setSelectedImage(image);
                    }}
                  >
                    <Image
                      height={200}
                      width={200}
                      className={'!h-auto w-full'}
                      key={image.href}
                      src={image.href}
                      alt={image.shotDest || 'exterior'}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="relative flex w-full flex-col overflow-hidden md:w-6/12">
          <CollapsibleSection
            title={'Trims'}
            renderSelection={
              <div className="flex justify-between text-base font-light leading-snug tracking-tight text-neutral-800">
                <div>{trim.trim}</div>
                <div>{priceFormatter(trim.msrp, PRICE_COMING_SOON)}</div>
              </div>
            }
          >
            <div className="flex flex-col gap-3">
              {trimStyles.map((thisTrim) => (
                <TrimCard
                  trim={thisTrim}
                  key={thisTrim.trimId}
                  selected={thisTrim.trimId === selectedTrim?.trimId}
                  onClick={() => {
                    setSelectedTrim(thisTrim);
                    clearSelectedExtendedOptionsList();
                  }}
                />
              ))}
            </div>
          </CollapsibleSection>

          {selectedTrim && (
            <CollapsibleSection
              title={'Exterior'}
              showResult
              className={`transition-opacity delay-500 duration-500`}
              renderSelection={
                <div className="flex justify-between text-base font-light leading-snug tracking-tight text-neutral-800">
                  <div>Color - {selectedExteriorColor.colorName}</div>
                  <div>{priceFormatter(selectedExteriorColor.msrp)}</div>
                </div>
              }
            >
              <div className="flex h-full w-full flex-wrap space-y-2 p-2">
                {allExteriors.map((image, index) => (
                  <ColorOption
                    key={index}
                    selected={
                      image.colorName === selectedExteriorColor?.colorName &&
                      image.code === selectedExteriorColor.code
                    }
                    {...image}
                    handleOnClick={() => {
                      if (selectedExteriorColor.code !== image.code) {
                        if (selectedExteriorColor.images.length > 0) {
                          setLoadingImage(true);
                        }
                      }
                      setSelectedExteriorColor(image);
                    }}
                  />
                ))}
              </div>
            </CollapsibleSection>
          )}

          {selectedTrim && (
            <CollapsibleSection
              title={'Interior Color'}
              className={`transition-opacity delay-500 duration-500`}
              renderSelection={
                <div className="flex justify-between text-base font-light leading-snug tracking-tight text-neutral-800">
                  <div>Color - {selectedInteriorColor.colorName}</div>
                  <div>
                    {selectedInteriorColor.msrp > 0
                      ? `$${selectedInteriorColor.msrp.toLocaleString()}`
                      : 'Included'}
                  </div>
                </div>
              }
            >
              <div className="flex flex-col gap-2">
                {allInteriors.map((color) => (
                  <ColorCard
                    key={color.colorName}
                    color={color}
                    selected={
                      color.colorName === selectedInteriorColor?.colorName
                    }
                    handleOnClick={setSelectedInteriorColor}
                  />
                ))}
              </div>
            </CollapsibleSection>
          )}

          {selectedTrim && (
            <CollapsibleSection
              title={POPULAR_OPTIONS}
              className={`transition-opacity delay-500 duration-500`}
            >
              <div className="relative flex max-h-[500px] flex-col gap-2 overflow-y-auto">
                {Object.keys(currentExtendedOptions).map((category) => {
                  const title = category.replaceAll('_', ' ');
                  return (
                    <div key={title}>
                      <div className="mb-[10px]">{title}</div>
                      {currentExtendedOptions[category].map(
                        (option: ExtendedOptionsFields, i) => {
                          return (
                            <ExtendedOptionCard
                              option={option}
                              key={`${option.code}-${category}-${selectedTrimId}-${i}`}
                              handleAddDispatch={setExtendedOption}
                              handleRemoveDispatch={removeExtendedOption}
                              isSelected={selectedExtendedOptionsCache.has(
                                option.description
                              )}
                            />
                          );
                        }
                      )}
                    </div>
                  );
                })}
              </div>
            </CollapsibleSection>
          )}

          <div className={'flex w-full grow flex-col justify-end'}>
            <Button
              variant={ButtonVariants.newPrimary}
              className={'mt-[40px] w-full'}
              disabled={
                !selectedInteriorColor ||
                !selectedExteriorColor ||
                !selectedTrim
              }
              analyticsEvent={GOOGLE_TRACK_INFO.reviewEVBuildButton}
              onClick={() => {
                next({
                  id: sessionId,
                  trim: trim.trim,
                  exteriorName: selectedExteriorColor.colorName,
                  interiorColorName: selectedInteriorColor.colorName,
                  extendOptionCodes: selectedExtendedOptions.map(
                    (option) => option.code
                  ),
                });
              }}
            >
              Review This Build
            </Button>
          </div>
        </div>
      </div>

      <div className="sticky" />
    </div>
  );
};
